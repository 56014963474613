/**
 * SingleSpa lifecycle methods for the OPTLY_X SCS
 * See https://single-spa.js.org/docs/building-applications#registered-application-lifecycle
 */
import _ from 'lodash';
import $ from 'jquery';
import config from 'atomic-config';

import { createInstance } from '@optimizely/react-sdk';

import cloneDeep from 'optly/clone_deep';
import $jQueryui from 'lib/jquery_ui/jquery-ui-1.13.2-optimizely-x'; // jQuery-ui 1.13.2 custom build with only used components installed
import initializeVue from 'core/ui/methods/initialize_vue';
import SegmentTracking from 'optly/modules/segment';
import flux from 'core/flux';
import { actions as LocalStorageMetaActions } from 'optly/modules/local_storage_meta';
import locationHelper from 'optly/location';
import { queryParamsToObject } from 'optly/utils/parse_query_params';
import router from 'core/router';
import sandbox from 'optly/sandbox';
import 'optly/translate';
import 'spectrum-colorpicker';
import ui from 'core/ui';

import {
  actions as AdminAccountActions,
  getters as AdminAccountGetters,
} from 'optly/modules/admin_account';
import OptlyAppApi from 'optly/optly_app_api';

import { actions as OptimizelyChampagneActions } from 'optly/modules/optimizely_champagne';

import ProfileDetailsActions from 'optly/modules/profile_details/actions';
import { actions as SentryActions } from 'optly/modules/sentry';
import {
  actions as UIVersionActions,
  enums as UIVersionEnums,
} from 'optly/modules/ui/version';
import PublicApiConsumerActions from 'optly/modules/public_api_consumer/actions';

import initializeP13NComponentsStores from 'bundles/p13n/components/initialize';
import initializeP13NSectionsStores from 'bundles/p13n/sections/initialize';
import initializeP13NModulesStores from 'bundles/p13n/modules/initialize';
import initializeCoreStores from 'core/initialize';
import initializeOptlyModulesStores from 'optly/modules/initialize';

import { actions as CurrentProjectActions } from 'optly/modules/current_project';

import caching from './caching';

import rootVM from './root_vm';
import routes from './routes';
import routingHelpers from './routing_helpers';

// TODO (FE-748) - Prevent config.get from blowing up on invalid key.
const isImpersonating = !!config.get('is_impersonating');
const projectId = config.get('current_project.id');

/**
 * Prepare the OPTLY_X SCS to be mounted for the first time.
 *
 * See https://single-spa.js.org/docs/building-applications#bootstrap
 *
 * @returns {Promise} - Resolved when bootstrapping is complete.
 */
export const bootstrap = () => {
  if (flux.evaluate(AdminAccountGetters.id)) {
    // Make the bootstrap method idempotent
    return Promise.resolve();
  }
  $jQueryui(); // Instantiate jQuery-ui 1.10.4 custom build with only used components installed
  initializeVue();

  initializeP13NComponentsStores();
  initializeP13NSectionsStores();
  initializeP13NModulesStores();
  initializeCoreStores();
  initializeOptlyModulesStores();

  window._ = _;
  // make jQuery available on the standard($ and jQuery) and custom ($j) window attributes
  window.$j = $;
  window.$ = $;
  window.jQuery = $;

  // Expose ui and flux to the global namespace.
  window.optly = {
    ui,
    flux,
  };
  /**
   * Expose optlyApp to the global namespace (window.optlyApp)
   *
   * Read through the documentation before making any changes.
   * Learn more about this module at /src/js/optly/optly_app_api/README.md
   *
   */
  window.optlyApp = OptlyAppApi;

  sandbox.loadInitialData();

  UIVersionActions.setActiveVersion(UIVersionEnums.versions.OPTIMIZELY_X);

  if (!__TEST__) PublicApiConsumerActions.init();

  // Only log sentry errors on Production or Staging
  if (config.get('env.USE_SENTRY')) {
    SentryActions.init();
  }

  let clientInstanceReady = Promise.resolve();

  SegmentTracking.tracking.initialize(
    config.get('account_info'),
    config.get('current_project'),
    isImpersonating || false,
  );
  if (locationHelper.getSearch().indexOf('share_token') === -1) {
    // Kickoff JS SDK initialization, except if a share token is being used to view results
    OptimizelyChampagneActions.setLogLevel();
    const clientInstance = createInstance({
      // The JS-SDK mutates the datafile passed here in such a way that it can only be used once.
      // As such, we must pass it a clone to ensure other consumers can use it.
      // See https://optimizely.atlassian.net/browse/OASIS-6301
      datafile: cloneDeep(config.get('fullstack_datafile')),
      eventDispatcher: OptimizelyChampagneActions.getEventDispatcher(),
    });
    // The js-sdk-lab expects the logger instance to be set on the client directly.
    clientInstance.logger = clientInstance.client.logger;
    OptimizelyChampagneActions.setClientInstance(clientInstance);
    clientInstanceReady = clientInstance
      .onReady()
      .then(() => {
        // Attach the client instance to this window variable so segmentjs can consume it.
        window.optimizelyClientInstance = clientInstance;
        const currentProject = config.get('current_project');
        CurrentProjectActions.setCurrentProject(currentProject);
      })
      .catch(error => {
        if (!__DEV__) {
          SentryActions.captureException(error);
        }
        /* eslint-disable */
        console.warn(
          `[OPTLY_X][Main] Optimizely JS SDK error with initialization: ${error.message}`,
        );
        /* eslint-enable */
      });
    OptimizelyChampagneActions.initializeAndObserveUserInfo(isImpersonating);
  }

  window.addEventListener('load', () => {
    setTimeout(() => {
      // Make sure 'loadEventEnd' has time to fire before tracking
      OptimizelyChampagneActions.trackSnippetPerformanceMetrics();
    }, 0);
  });

  try {
    LocalStorageMetaActions.initialize();
    caching.setupProjectLocalEntityCache(projectId);
  } catch (e) {
    console.warn('Error setting up local entity cache: ' + e); // eslint-disable-line
  }

  try {
    const signInEvent = new CustomEvent('optly-signin', {
      detail: {
        optlyConfig: window.optlyConfig,
      },
    });
    window.dispatchEvent(signInEvent);
  } catch (e) {
    console.warn('CustomEvent not supported'); // eslint-disable-line
  }

  return clientInstanceReady;
};

/**
 * Mount the <OptimizelyApp /> at the node identified by the selector.
 * See https://single-spa.js.org/docs/building-applications#mount
 *
 * @param {String} selector - The CSS selector to identify the DOM node for insertion.
 * @returns {Promise} Promise that resolves when the component mount is complete.
 */
export const mount = ({ selector }) => {
  return ui.mountRootVM(rootVM, selector).then(() => {
    // loading routes and dispatch
    routingHelpers.initialize();
    router.loadRoutes(routes);
    router.go(router.getLocation());

    // Determine if the user is signing in for the first time and show customized collaborator details dialog copy if so
    const query = queryParamsToObject(window.location.href);
    const IS_FIRST_SIGNIN = 'is_first_signin';
    const IS_WELCOME_PATH = '/v2/welcome';
    if (
      query[IS_FIRST_SIGNIN] ||
      !!window.location.pathname.match(IS_WELCOME_PATH)
    ) {
      ProfileDetailsActions.setIsFirstSignin();
    }

    if (!isImpersonating) {
      ProfileDetailsActions.maybeShowCollaboratorDetailsDialog();
    }
  });
};

/**
 * Unmount the component at the node identified by the selector.
 * See https://single-spa.js.org/docs/building-applications#unmount
 *
 * @param {String} selector - The CSS selector to identify the DOM node for insertion.
 */
export const unmount = async ({ selector }) => {
  ui.reset(false, selector);
};

export default {
  bootstrap,
  mount,
  unmount,
};
